.uploaded-file-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.uploaded-file {
  list-style-type: none;
  padding: 0;
  margin: 16px;
  position: relative;
}

.upload-status-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0.8;
  padding: 16px;
}

.status-icon {
  background-color: white;
  font-size: 64px;
  line-height: 100%;
  font-weight: 500;
}
.status-icon-error {
  color: red;
}
.status-icon-success {
  color: green;
}

.uploaded-file button.dismiss {
  position: absolute;
  right: -32px;
  top: -32px;
  color: brown;
  background-color: white;
  border-radius: 32px;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 0;
}

.uploaded-file img {
  width: 220px;
}
