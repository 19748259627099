.spinner {
  display: flex;
  justify-content: center;
  margin: auto;
}

button .spinner {
  position: absolute;
  top: calc(50% + 2px);
  left: 50%;
  transform: translate(-50%, -50%);
}
