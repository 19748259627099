@media print {
  html,
  body,
  #root,
  main,
  article,
  section {
    height: initial !important;
    overflow: visible !important;
    position: static !important;
    display: block !important;
    width: auto !important;
    border: 0 !important;
    float: none !important;
  }

  html,
  body,
  div,
  main {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@page {
  size: A4;
  margin: 15mm 10mm;
}